var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticClass:"text-right d-md-block d-none",attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"bottom":"","large":"","color":"primary"},on:{"click":function($event){_vm.handleStoreAccount((_vm.id = null))}}},[_vm._v("Novo")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items-per-page":-1,"headers":_vm.headers,"items":_vm.accounts,"loading":_vm.loading,"hide-default-footer":""},on:{"click:row":function($event){return _vm.handleStoreAccount($event.id)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.employees",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.employees.map(function (row) { return "@" + row.username; }).join(" | "))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status}})]}},{key:"item.doc",fn:function(ref){
var item = ref.item;
return [(item.person_type == 'fisica')?_c('span',[_vm._v(" "+_vm._s(item.cpf)+" ")]):_vm._e(),(item.person_type == 'juridica')?_c('span',[_vm._v(" "+_vm._s(item.cnpj)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('StoreAccountForm',{ref:"StoreAccountForm",on:{"store":function($event){return _vm.select()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }