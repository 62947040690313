<template>
  <div>
    <v-row>
      <v-col cols="4">
        <!-- <app-text-search-field @input="handleSearchInput($event)" /> -->
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          @click="handleStoreAccount((id = null))"
          >Novo</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :items-per-page="-1"
          :headers="headers"
          :items="accounts"
          :loading="loading"
          @click:row="handleStoreAccount($event.id)"
          hide-default-footer
        >
          <template v-slot:[`item.name`]="{ item }">
            <!-- <status-chip :value="item.status" /> -->
            <!-- <span class="ibb-banco-nordeste fa-4x mr-4" style="font-size:25px; color: #a80a35"></span> -->
            {{ item.name }}
          </template>
          <template v-slot:[`item.employees`]="{ item }">
            <!-- <status-chip :value="item.status" /> -->
            <!-- <span class="ibb-banco-nordeste fa-4x mr-4" style="font-size:25px; color: #a80a35"></span> -->
            <!-- <template v-for="employee of item.employees">
              @{{ employee.username }} |
            </template> -->
            {{ item.employees.map((row) => "@" + row.username).join(" | ") }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
          <template v-slot:[`item.doc`]="{ item }">
            <span v-if="item.person_type == 'fisica'">
              {{ item.cpf }}
            </span>
            <span v-if="item.person_type == 'juridica'">
              {{ item.cnpj }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <StoreAccountForm ref="StoreAccountForm" @store="select()" />
    <!-- <StoreSupplierForm @store="select()" ref="StoreSupplierForm" /> -->
  </div>
</template>

<script>
import StoreAccountForm from "@/components/account/form/StoreAccountForm.vue";

export default {
  components: { StoreAccountForm },

  data: () => ({
    headers: [
      { text: "Name", align: "start", value: "name" },
      { text: "Acesso", align: "start", value: "employees" },
      { text: "Tipo", align: "center", value: "type" },
      // { text: "Tipo de pessoa", align: "center", value: "person_type" },
      // { text: "CNPJ/CPF", align: "center", value: "doc" },
      // { text: "Status", align: "center", value: "status" },
    ],

    options: {},

    loading: false,

    searchParams: {
      page: 1,
      orderBy: "name",
      orderDirection: "asc",
      text: null,
    },
  }),

  computed: {
    accounts() {
      return this.$store.state.accounts.accounts;
    },
  },

  created() {
    this.select();
  },

  methods: {
    async select() {
      this.$store.dispatch("accounts/index");
    },

    handleStoreAccount(id = null) {
      this.$refs.StoreAccountForm.open(id);
    },
  },
};
</script>

<style>
</style>