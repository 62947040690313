<template>
  <v-select :items="banks" item-text="name" v-bind="$attrs" v-on="$listeners"
    :item-value="(value) => { return { code: value.code, name: value.name } }">
    <template v-slot:selection="{ item }">
      <span :class="[item.icon, 'fa-4x ml-1']" :style="'font-size: 18px; color: ' + item.color"></span>
      <span class="ml-2">{{ item.name }}</span>
    </template>

    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-icon>
          <span :class="[item.icon, 'fa-4x']" :style="'font-size: 18px; color: ' + item.color"></span>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {

  data: () => ({
    banks: [
      { code: 1, name: 'Banco do Brasil', icon: 'ibb-banco-brasil', color: '#003882' },
      { code: 4, name: 'Banco do Nordeste', icon: 'ibb-banco-nordeste', color: '#a80a35' },
      { code: 104, name: 'Caixa Econômica', icon: 'ibb-caixa', color: '#016db8' },
      { code: 756, name: 'Sicoob', icon: 'ibb-sicoob', color: '#003641' },
      { code: 748, name: 'Sicredi', icon: 'ibb-sicredi', color: '#008000' },
    ]
  }),

}
</script>

<style scoped></style>
